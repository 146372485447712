<template>
  <div class="section-join" id="join">
    <div class="section-join__title">Присоединяйтесь к нашей команде сегодня!</div>
    <!-- <div class="section-join__desc">Работайте с лучшими умами в бизнесе.</div> -->
    <div class="section-join__button" @click="openModal">Найдите работу своей мечты</div>
  </div>
</template>

<script>
export default {
  methods: {
    openModal() {
      this.$emit('openModal')
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.section-join {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../assets/images/join-bg.png');
  background-size: cover;
  background-position: center;
  padding: 85px 0 50px;
  color: #FFFFFF; 

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.1px;
    margin-bottom: 16px;

    @include breakpoint(phablet) {
      font-size: 32px;
      text-align: center;
    }

    @include breakpoint(mobile) {
      font-size: 28px;
    }
  }

  &__desc {
    font-weight: 400;
    margin-bottom: 32px;
  }

  &__button {
    @include button-white-bordered;
    background: #7675FF;
    border-color: #7675FF;
    border-radius: 5px;

    &:hover {
      border-color: #fff;
    }
  }
}
</style>
