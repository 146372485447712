<template>
  <div class="modal">
    <div class="modal-shadow" @click="closeModal"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss">
@import "../assets/scss/mixins.scss";
@import "../assets/scss/animations.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  &__form {
    position: relative;
    z-index: 2;
  }

  &-shadow {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(28, 59, 189, 0.4);
    backdrop-filter: blur(10px);
    animation: showModalShadow .7s;
  }
}
</style>