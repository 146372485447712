<template>
  <div class="section-advantages" id="advantages">
    <div 
      class="section-advantages__item"
      v-for="advantage in advantagesList"
      :key="advantage.name"
    >
      <div class="section-advantages__image-wrap">
        <img class="section-advantages__image" :src="require(`@/assets/images/${advantage.image}`)">
        <img class="section-advantages__image-shadow" :src="require(`@/assets/images/shadow-${advantage.image}`)">
      </div>
      <div class="section-advantages__item-name">{{ advantage.name }}</div>
      <div class="section-advantages__item-desc">{{ advantage.desc }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      advantagesList: [
        {
          name: 'Консультирование',
          desc: 'Проводим аудит цифрового бизнеса. Используем наш опыт для определения необходимости проведения нагрузочного тестирования.',
          image: 'dialog.png',
        },
        {
          name: 'Подбор команды',
          desc: 'Формируем команду профессионалов нагрузочного тестирования под уникальные нужды проекта.',
          image: 'pencil.png',
        },
        {
          name: 'Инжиниринг',
          desc: 'Исследуем технологические процессы для выявления проблем производительности.',
          image: 'gear.png',
        },
        {
          name: 'Анализ и оптимизация',
          desc: 'Производим оптимизацию производительности технологической платформы. Находим оптимальную конфигурацию системы.',
          image: 'forms.png',
        },
        {
          name: 'Поддержка',
          desc: 'Внедряем поддержку процесса нагрузочного тестирования для устойчивого развития бизнеса.',
          image: 'suitcase.png',
        },
      ]
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.section-advantages {
  display: flex;
  @include breakpoint(desktop) {
    flex-direction: column;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 49px;
    background-color: #1C3BBD;
    cursor: pointer;
    transition: .1s;

    @include breakpoint(desktop) {
      justify-content: center;
      align-items: flex-start;
      padding: 32px calc((100% - 620px)/2) 32px calc((100% - 620px)/2 + 170px);
      min-height: 240px;
    }

    @include breakpoint(phablet) {
      padding: 32px 0;
      align-items: center;
      min-height: auto;
    }

    &:hover {
      background-color: #F0E0FF;

      .section-advantages__item-name, .section-advantages__item-desc {
        color: #1C3BBD;
      }

      .section-advantages__image {
        transform: translateY(-20%) rotate(7deg);
      }

      .section-advantages__image-shadow {
        transform: skew(-10deg) translateY(-4%) translateX(10%);
        opacity: .6;
      }
    }

    &-name, &-desc {
      color: #FFFFFF;
      transition: .1s;
    }

    &-name {
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: 0.1px;
    }

    &-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.25px;

      @include breakpoint(desktop) {
        text-align: left;
      }

      @include breakpoint(phablet) {
        text-align: center;
        max-width: 170px;
      }
    }
  }
  
  &__image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include breakpoint(desktop) {
      position: absolute;
      left: calc((100% - 620px)/2);
    }

    @include breakpoint(phablet) {
      position: initial;
      left: inherit;
    }
  }

  &__image, &__image-shadow {
    width: 155px;
    transition: .5s;
  }

  &__image {
    position: relative;
    z-index: 1;
  }

  &__image-shadow {
    position: absolute;
    z-index: 0;
  }
}
</style>
