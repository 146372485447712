<template>
  <div :class="['ui-title', {'ui-title--line': line}, {'ui-title--reverse': reverse}]">{{text}}</div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    line: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.ui-title {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  color: #1C3BBD;
  white-space: pre;
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  margin-bottom: 16px;

  @include breakpoint(tablet) {
    font-size: 32px;
  }

  @include breakpoint(phablet) {
    font-size: 32px;
  }

  @include breakpoint(mobile) {
    font-size: 28px;
  }

  &--line {
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background: #1C3BBD;
      margin-left: 20px;
    }
  }

  &--reverse {
    flex-direction: row-reverse;

    &:after {
      margin-left: 0;
      margin-right: 20px;
    }
  }
}
</style>
