<template>
  <div class="section-clients" id="clients">
    <div class="container">
      <ui-title class="section-clients__title" text="Наши клиенты" line/>
      <div class="section-clients__content-wrap">
        <div class="section-clients__item">
          <div class="section-clients__item-name">Альфа-банк</div>
          <div class="section-clients__item-desc">Альфа-Банк — универсальный банк, осуществляющий все основные виды банковских операций, представленных на рынке финансовых услуг, включая обслуживание частных и корпоративных клиентов, инвестиционный банковский бизнес, лизинг, факторинг и торговое финансирование, является одним из лидеров рынка. Наши специалисты участвовали в развитии таких платформ кредитования, как Матрица кредитных предложений и Альфа-Финанс.</div>
          <div class="section-clients__item-add">
            <div class="section-clients__item-name">Проекты Альфа-банка</div>
            <div class="section-clients__item-desc"> Единый фронт</div>
            <div class="section-clients__item-desc"> Альфа-Финанс</div>
            <div class="section-clients__item-desc"> Альфа-Бизнес Online</div>
            <div class="section-clients__item-desc"> Альфа-линк: реализация зарплатного проекта </div>
            <div class="section-clients__item-desc"> Risk Monitoring</div>
          </div>
        </div>
        <div class="section-clients__item">
          <div class="section-clients__item-name">X5 Retail group</div>
          <div class="section-clients__item-desc">Х5 Group — ведущая продуктовая розничная компания России, которая управляет портфелем брендов сетевых магазинов «Пятёрочка», «Перекрёсток», «Карусель», а также цифровыми платформами. У специалистов нашей компании есть опыт работы на ключевых проектах X5 Group таких как, Feedback и HR Portal.</div>
          <div class="section-clients__item-add">
            <div class="section-clients__item-name">Проекты X5 Retail group</div>
            <div class="section-clients__item-desc"> Система маркировки Маркус</div>
            <div class="section-clients__item-desc"> SAP ERP</div>
            <div class="section-clients__item-desc"> Мобильные приложения Чижик, ТС5</div>
            <div class="section-clients__item-desc"> Система управления складом WMS</div>
            <div class="section-clients__item-desc"> Сервис лояльности Апельсин</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiTitle from '@/components/ui/UiTitle'

export default {
  components: {
    UiTitle,
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.section-clients {
  display: flex;
  justify-content: center;
  padding-bottom: 88px;

  &__title {
    margin-bottom: 39px;
  }

  &__content-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    padding-right: 52px;
    border-right: 3px solid #1C3BBD;
    width: 50%;

    @include breakpoint(laptop) {
      border-right: 0;
      padding-right: 10px;
    }

    @include breakpoint(tablet) {
      padding-right: 0;
      width: 55%;
    }

    @include breakpoint(phablet) {
      width: 100%;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    opacity: .3;
    transition: .3s;

    &:hover {
      opacity: 1;
    }

    &-name {
      font-weight: 600;
      font-size: 40px;
      line-height: 120%;
      letter-spacing: 0.1px;
      margin-bottom: 16px;

      @include breakpoint(laptop) {
        font-size: 32px;
      }

      @include breakpoint(tablet) {
        font-size: 26px;
      }
    }
    
    &-desc {
      font-weight: 400;
      color: #1C3BBD;
    }


    &-add {
      position: absolute;
      right: 0;
      align-self: center;
      transform: translateX(calc(95px + 100%));

      @include breakpoint(laptop) {
        transform: translateX(calc(40px + 100%));

        svg {
          height: 100%;
          width: 100%;
        }
      }

      @include breakpoint(phablet) {
        right: inherit;
        position: initial;
        align-self: flex-start;
        transform: translateX(0);
        width: 100%;
        margin-top: 16px;
      }

      svg {
        path, ellipse {
          transition: .3s;
        }
      }
    }
  }
}
</style>
