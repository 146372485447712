<template>
  <div :class="['header', {'header--scrolled':  windowScroll > 60}]">
    <div class="container">
      <div class="header__logo" @click="scrollToSlide('app')"><icon-logo/></div>
      <template v-if="isDesktopMenu">
        <div class="header__nav">
          <div 
            class="header__nav-item" 
            v-for="item in navList" :key="item.key"
            @click="scrollToSlide(item.key)"
          >{{ item.text }}</div>
        </div>
        <div 
          class="header__contact-us" 
          @click="scrollToSlide('contact')"
        >Связаться с нами</div>
      </template>
      <div class="header__burger" v-else @click="openMobileNav">
        <icon-burger/>
      </div>
    </div>
  </div>
</template>

<script>
import IconLogo from '@/components/icons/IconLogo'
import IconBurger from '@/components/icons/IconBurger'

export default {
  name: 'Header',
  components: {
    IconLogo,
    IconBurger,
  },
  props: {
    navList: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      windowScroll: window.scrollY,
      isDesktopMenu: true,
    }
  },
  mounted() {
    this.setWindowConfig()
    window.addEventListener('scroll', () => {
      this.windowScroll = window.scrollY
    })
    window.addEventListener('resize', () => {
      this.setWindowConfig()
    })
  },
  methods: {
    scrollToSlide(id) {
      this.$emit('scrollToSlide', id)
    },
    openMobileNav() {
      this.$emit('openMobileNav')
    },
    setWindowConfig() {
      if (window.innerWidth > 1024) this.isDesktopMenu = true
      else this.isDesktopMenu = false
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll')
    window.removeEventListener('resize')
  }
}
</script>

<style lang="scss">
@import "../assets/scss/mixins.scss";

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  height: 120px;
  line-height: 24px;
  transition: .2s;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--scrolled {
    background: #1C3BBD;
    box-shadow: 0px 341px 137px rgba(38, 45, 52, 0.02), 0px 192px 115px rgba(38, 45, 52, 0.08), 0px 85px 85px rgba(38, 45, 52, 0.13), 0px 21px 47px rgba(38, 45, 52, 0.15), 0px 0px 0px rgba(38, 45, 52, 0.15);
  }

  &__logo {
    width: 100%;
    cursor: pointer;

    @include breakpoint(laptop) {
      width: 200px;

      svg {
        width: 100%;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-right: 65px;

    @include breakpoint(desktop) {
      margin-right: 40px;
      gap: 20px;
    }

    @include breakpoint(laptop) {
      margin-right: 0;
      gap: 16px;
      font-size: 14px;
      letter-spacing: 0.4px;
    }

    &-item {
      @include button-simple;
    }
  }

  &__contact-us {
    @include button-white-bordered;
  }

  &__burger {

  }
}
</style>
