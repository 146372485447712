<template>
  <div class="section-help" id="help">
    <div class="section-help__title">Почему необходимо нагрузочное тестирование?</div>
    <div class="section-help__desc">Медленные сайты раздражают клиентов</div>
    <div class="section-help__desc">Понимание возможностей ресурса максимизирует прибыль</div>
    <div class="section-help__desc">Эмуляция сбоев в тесте несравнимо дешевле реального сбоя</div>

    <div class="section-help__title">Чем поможет нагрузочное тестирование?</div>
    <div class="section-help__desc">Избежание потерь клиентов в результате сбоев в работе сервиса</div>
    <div class="section-help__desc">Уменьшение трат на серверы и поддержку</div>
    <div class="section-help__desc">Уменьшение рисков простоя бизнеса</div>
    <div class="section-help__desc">Ускорение работы сервиса для клиентов</div>
    <ui-button 
      text="Связаться с нами"
      class="section-help__contact" 
      @click="scrollToSlide('contact')"
    />
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton'

export default {
  components: {
    UiButton,
  },
  methods: {
    scrollToSlide(id) {
      this.$emit('scrollToSlide', id)
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.section-help {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 0 78px;

  @include breakpoint(phablet) {
    text-align: center;
  }
  
  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.1px;
    color: #1E293B;
    margin-bottom: 20px;
    margin-top: 32px;

    &:first-child {
      margin-top: 0;
    }

    @include breakpoint(laptop) {
      text-align: center;
    }

    @include breakpoint(phablet) {
      font-size: 28px;
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 10px;

    @include breakpoint(phablet) {
      font-size: 14px;
    }
  }

  &__contact {
    margin-top: 32px;
  }
}
</style>
