<template>
  <div class="section-promo" id="promo">
    <div class="container">
      <div class="section-promo__header">Используйте свои ресурсы по максимуму</div>
      <div class="section-promo__desc">Нагрузочное тестирование и анализ производительности</div>
      <div class="section-promo__buttons-wrap">
        <div class="section-promo__contact-us" @click="scrollToSlide('contact')">Связаться с нами</div>
        <div class="section-promo__more" @click="scrollToSlide('advantages')">Подробнее</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToSlide(id) {
      this.$emit('scrollToSlide', id)
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.section-promo {
  height: 100vh;
  background:  url('../../assets/images/background.png'), linear-gradient(321.96deg, #163B96 9.39%, #2D5BCE 81.47%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  display: flex;
  justify-content: center;
  color: #FFFFFF;

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    justify-content: center;

    @include breakpoint(phablet) {
      align-items: center;
    }
  }

  &__header {
    max-width: 72%;
    font-weight: 600;
    font-size: 84px;
    line-height: 120%;
    margin-bottom: 16px;

    @include breakpoint(laptop) {
      font-size: 64px;
      line-height: 110%;
    }

    @include breakpoint(phablet) {
      font-size: 48px;
    }

    @include breakpoint(phablet) {
      max-width: 100%;
      text-align: center;
    }
  }

  &__desc {
    max-width: 56%;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 40px;

    @include breakpoint(laptop) {
      font-size: 32px;
      line-height: 40px;
    }

    @include breakpoint(phablet) {
      font-size: 24px;
      line-height: 32px;
    }

    @include breakpoint(phablet) {
      max-width: 100%;
      text-align: center;
    }
  }

  &__buttons-wrap {
    display: flex;
    align-items: center;
    gap: 8px;

    @include breakpoint(mobile) {
      flex-direction: column;
    }
  }

  &__contact-us {
    @include button-white-bordered;
  }

  &__more {
    padding: 8px 32px;
    @include button-simple;
  }
}
</style>
