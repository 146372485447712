<template>
  <div class="ui-button" @click.stop="$emit('click')">{{text}}</div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="scss">
.ui-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 6px 32px;
  color: #fff;
  background: #1C3BBD;
  opacity: 0.8;
  border: 2px solid #1C3BBD;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background-color: transparent;
    color: #1C3BBD;
  }
}
</style>
