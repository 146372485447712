<template>
  <div id="app">
    <Header 
      :navList="navList"
      @scrollToSlide="openSlide" 
      @openMobileNav="setMobileNav"
    />
    <SectionPromo @scrollToSlide="openSlide"/>
    <SectionService @scrollToSlide="openSlide"/>
    <SectionAdvantages/>
    <SectionJoin @openModal="setModal"/>
    <SectionHelp @scrollToSlide="openSlide"/>
    <SectionLearning @scrollToSlide="openSlide"/>
    <SectionClients/>
    <SectionAboute @scrollToSlide="openSlide"/>
    <SectionContact/>
    
    <Modal 
      v-if="showModal" 
      @closeModal="setModal"
    >
      <Form class="modal__form"/>
    </Modal>

    <Modal 
      v-if="showMobileNav" 
      @closeModal="setMobileNav"
    >
      <MobileMenu 
        :navList="navList"
        @scrollToSlide="openSlide"
        @closeModal="setMobileNav"
      />
    </Modal>
  </div>
</template>

<script>
import Header from '@/components/Header'
import MobileMenu from '@/components/MobileMenu'
import Modal from '@/components/Modal'
import Form from '@/components/Form'
import SectionPromo from '@/components/sections/SectionPromo'
import SectionService from '@/components/sections/SectionService'
import SectionAdvantages from '@/components/sections/SectionAdvantages'
import SectionAboute from '@/components/sections/SectionAboute'
import SectionJoin from '@/components/sections/SectionJoin'
import SectionLearning from './components/sections/SectionLearning.vue'
import SectionHelp from '@/components/sections/SectionHelp'
import SectionClients from '@/components/sections/SectionClients'
import SectionContact from '@/components/sections/SectionContact'


export default {
  name: 'App',
  components: {
    Header,
    MobileMenu,
    Modal,
    Form,
    SectionPromo,
    SectionService,
    SectionAdvantages,
    SectionAboute,
    SectionJoin,
    SectionLearning,
    SectionHelp,
    SectionClients,
    SectionContact,

  },
  data() {
    return {
      showModal: false,
      showMobileNav: false,
      navList: [
        {
          key: 'service',
          text: 'Услуги',
        },
        {
          key: 'advantages',
          text: 'Процесс работы',
        },
        
        {
          key: 'join',
          text: 'Карьера',
        },
        // {
        //    key: 'results',
        //    text: 'Наши результаты',
        // },
        {
          key: 'learning',
          text: 'Обучение',
        },
        {
          key: 'clients',
          text: 'Наши клиенты',
        },
        {
          key: 'aboute',
          text: 'О компании',
        },      ],
    }
  },
  methods: {
    openSlide(id){
      const yOffset = -120; 
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    },
    setModal() {
      this.showModal = !this.showModal
    },
    setMobileNav() {
      this.showMobileNav = !this.showMobileNav
    },
  },
}
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
@import "./assets/scss/mixins.scss";
@import "./assets/fonts/fonts.css";

.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 15px;

  @include breakpoint(desktop) {
    margin: 0 20px;
  }

  @include breakpoint(laptop) {
    margin: 0 25px;
  }
}
</style>
